// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CloudRunPathBuilder, Env, IPlatformAdminEnvironment } from 'shared-lib'

const env: Env = 'dev'
//const pathBuilder = new AppEnginePathBuilder(env)
const pathBuilder = new CloudRunPathBuilder('dr32hxly6a')

export const environment: IPlatformAdminEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyCnP-U4Q9cCTJ6jCB5aAfdNdPm66Owq6_4',
    authDomain: 'cnect-dev.firebaseapp.com',
    databaseURL: 'https://cnect-dev.firebaseio.com',
    projectId: 'cnect-dev',
    storageBucket: 'cnect-dev.appspot.com',
    messagingSenderId: '114692117909',
    appId: '1:114692117909:web:74e5f41c97794b8174129c',
    measurementId: 'G-2VQ0MJS397',
  },

  services: {
    jobs: pathBuilder.build('jobs'),
    registration: pathBuilder.build('registration'),
    user: pathBuilder.build('user'),
    auth: pathBuilder.build('auth'),
    files: pathBuilder.build('files'),
    customer: pathBuilder.build('customer'),
    notifications: pathBuilder.build('notifications'),
  },

  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: true, // Look to the auth guard for use of this value
  themesEnabled: true,
  v2ReCaptcha: '6LcVas0aAAAAAAcHaLi9835I5X1EPovMGnsTVm_Z',
  tenantId: 'PLATFORM-USER-n81ik',
  algoliaAppId: 'SH1DAV8W6D',
  cdnCname: 'dev-content.cnected.com',
  version: '4e9e9e2',
  basePlatformHost: 'dev-admin.cnect.jobs',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
